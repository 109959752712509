import { OrderType } from '@/api/order/models/OrderType';
import { Language } from '@/i18n/Language';
import {
  createCancelSigningUrl,
  createCompleteSigningUrl,
} from '@/pages/Order/steps/OrderDetails/utils/createSigningUrl';
import { FundOrder } from '@/pages/Order/types/Order';

import { OrderPlacementDto } from '../dtos/OrderPlacementDto';
import { PostOrderDto } from '../dtos/PostOrderDto';
import { OrderPlacement } from '../models/OrderPlacement';

export const mapPostFundOrderModelToDto = (
  organizationCmId: string,
  orderType: OrderType,
  fundOrder: FundOrder,
  language: Language,
): PostOrderDto => {
  const signCancelUrl = createCancelSigningUrl(organizationCmId, orderType);
  const signCompleteUrl = createCompleteSigningUrl(organizationCmId, orderType);

  return {
    order: {
      portfolioNumber: fundOrder.portfolioId,
      orderPlacements: fundOrder.orderPlacements.map(
        mapOrderPlacementModelToDto,
      ),
      signatories: fundOrder.signatories,
      signingProviderId: fundOrder.signingProvider,
    },
    language,
    signCompleteUrl,
    signCancelUrl,
  };
};

const mapOrderPlacementModelToDto = (
  orderPlacement: OrderPlacement,
): OrderPlacementDto => {
  return {
    isin: orderPlacement.isin,
    fundName: orderPlacement.fundName,
    value: orderPlacement.value,
    valueType: orderPlacement.valueType,
    placementType: orderPlacement.placementType,
    currency: orderPlacement.currency,
    accountId: orderPlacement.accountId,
    payment: orderPlacement.payment,
    externalAccountNumber: orderPlacement.externalAccountNumber,
    hash: orderPlacement.hash,
    id: orderPlacement.id,
  };
};
