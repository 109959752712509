import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';

import { useFundListQuery } from '@/api/funds/fundsApi';
import {
  isDiscretionaryType,
  isFundType,
  OrderType,
} from '@/api/order/models/OrderType';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Pending } from '@/components/Pending';
import { Show } from '@/components/Show';
import { useConsumeQueryParameter } from '@/util/useConsumeQueryParameter';

import { useExistingOrder } from './hooks/useExistingOrder';
import { useFetchRequiredFormData } from './hooks/useFetchRequiredFormData';
import { DiscretionaryOrder, FundOrder, Order } from './types/Order';
import { getFundOrderFromOrder } from './utils/getFundOrderFromOrder';

interface OrderPageState {
  orderType: OrderType;
  currentOrder: Order | undefined;
  currentFundOrder: FundOrder | undefined;
  setCurrentFundOrder: (fundOrder: FundOrder | undefined) => void;
  currentDiscretionaryOrder: DiscretionaryOrder | undefined;
  setCurrentDiscretionaryOrder: (
    discretionaryOrder: DiscretionaryOrder | undefined,
  ) => void;
  /** Order is already existing, only needs signing. */
  orderForSigning: { id: string; signUrl: string } | undefined;
  signingCancelled: boolean;
  hasExistingOrder: boolean;
  cancel: () => void;
  reset: () => void;
}

const OrderPageContext = React.createContext<OrderPageState | null>(null);

interface Props extends PropsWithChildren {
  orderType: OrderType;
}

export const OrderPageProvider: FC<Props> = ({ orderType, children }) => {
  const [currentFundOrder, setCurrentFundOrder] = useState<
    FundOrder | undefined
  >();

  const [currentDiscretionaryOrder, setCurrentDiscretionaryOrder] = useState<
    DiscretionaryOrder | undefined
  >();

  const signingCancelled = useConsumeQueryParameter('signingCancelled');

  const { existingOrder, signUrl, existingOrderIsPending } = useExistingOrder();

  const organizationCmId = useOrganizationCmId();

  const navigate = useNavigate();

  const hasRequiredData = useFetchRequiredFormData();
  const { data: fundList } = useFundListQuery();

  const reset = (): void => {
    setCurrentFundOrder(undefined);
    setCurrentDiscretionaryOrder(undefined);
  };

  const cancel = (): void => {
    navigate(`/${organizationCmId}`);
  };

  useEffect(() => {
    reset();
  }, [orderType]);

  // Set the appropriate order if there is an existing order.
  useEffect(() => {
    if (existingOrder && fundList && isFundType(orderType)) {
      setCurrentFundOrder(getFundOrderFromOrder(existingOrder, fundList));
    }

    if (existingOrder && isDiscretionaryType(orderType)) {
      setCurrentDiscretionaryOrder({
        userId: existingOrder.userId,
        portfolioId: existingOrder.portfolioNumber,
        signatories: existingOrder.signatories,
        signingProvider: existingOrder.signingProvider,

        // Discretionary orders
        amount: existingOrder.amount,
        currency: existingOrder.currency,
        messageToSam: existingOrder.messageToSam,

        // Deposit orders
        toPortfolioBankAccountNumber:
          existingOrder.toPortfolioBankAccountNumber,
        expectedDepositDate: existingOrder.expectedDepositDate,

        // Withdrawal orders
        fromPortfolioBankAccountNumber:
          existingOrder.fromPortfolioBankAccountNumber,
        externalBankAccount: existingOrder.externalBankAccount,
        expectedTransferDate: existingOrder.expectedTransferDate,
      });
    }
  }, [existingOrder, fundList]);

  const contextData = useMemo<OrderPageState>(() => {
    return {
      orderType,
      currentOrder: currentFundOrder || currentDiscretionaryOrder,
      currentFundOrder,
      setCurrentFundOrder,
      currentDiscretionaryOrder,
      setCurrentDiscretionaryOrder,
      orderForSigning: existingOrder && {
        id: existingOrder.id,
        signUrl,
      },
      signingCancelled: Boolean(signingCancelled),
      hasExistingOrder: !!existingOrder,
      cancel,
      reset,
    };
  }, [
    orderType,
    currentFundOrder,
    currentDiscretionaryOrder,
    existingOrder,
    signingCancelled,
    signUrl,
  ]);

  return (
    <>
      <Show when={existingOrderIsPending || !hasRequiredData}>
        <Pending center />
      </Show>
      <Show when={hasRequiredData} animate>
        <OrderPageContext.Provider value={contextData}>
          {children}
        </OrderPageContext.Provider>
      </Show>
    </>
  );
};

export const useOrderPageContext = (): OrderPageState => {
  const context = useContext(OrderPageContext);

  if (!context) {
    throw new Error(
      `${useOrderPageContext.name} must be called within a ${OrderPageProvider.name}.`,
    );
  }

  return context;
};
