import React from 'react';
import { styled } from 'styled-components';

import { TableRowCell } from './TableRowCell';
import { TableRowHeader } from './TableRowHeader';

interface Props {
  left: string;
  right: string;
}

export const Row: React.FC<Props> = ({ left, right }) => {
  return (
    <tr>
      <TableRowHeader>{left}</TableRowHeader>
      <Cell>{right}</Cell>
    </tr>
  );
};

const Cell = styled(TableRowCell)`
  text-align: right;
`;
