import InlineAlert from '@storeblocks/inline-alert';
import LoadingIndicator from '@storeblocks/loading-indicator';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { useUsersQuery } from '@/api/users/usersApi';
import { useCurrentUserInfo } from '@/auth/hooks';
import { Show } from '@/components/Show';
import { UserType } from '@/config/types';
import { useFms } from '@/hooks/useFms';
import { padding } from '@/styles/variables';

import { AddAllUsersModal } from './AddAndEditUserModal/AddAllUsers';
import { IndividualUsersTable } from './IndividualUsersTable';
import { Toolbar } from './Toolbar';
import { useUserSearch } from './useUserSearch';

export const IndividualUserAdmin: FC = () => {
  const texts = useFms();

  const [searchValue, setSearchValue] = useState<string | undefined>();

  const { userType } = useCurrentUserInfo();

  const usersQuery = useUsersQuery(undefined, {
    skip: userType !== UserType.Internal,
  });

  const { activeUsers, inactiveUsers } = useUserSearch(
    searchValue,
    usersQuery.data,
  );

  if (usersQuery.isError) {
    return (
      <Wrapper>
        <InlineAlert variant="error" title={texts.admin.user.loadError} />
      </Wrapper>
    );
  }

  if (usersQuery.isLoading) {
    return (
      <Wrapper>
        <LoadingIndicator />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Toolbar
        onSearchParamsChange={(params) => setSearchValue(params.query)}
        modal={AddAllUsersModal}
      />

      <Show when={inactiveUsers.length > 0}>
        <Wrapper>
          <Label>{texts.admin.user.inactiveUsers}</Label>
          <IndividualUsersTable individualUsers={inactiveUsers} />
        </Wrapper>
      </Show>

      <Show when={activeUsers.length > 0}>
        <Wrapper>
          {/* Only show label if the table above is visible. */}
          <Show when={inactiveUsers.length > 0}>
            <Label>{texts.admin.user.users}</Label>
          </Show>

          <IndividualUsersTable individualUsers={activeUsers} />
        </Wrapper>
      </Show>

      <Show when={activeUsers.length === 0 && inactiveUsers.length === 0}>
        <InlineAlert title={texts.admin.users.none} />
      </Show>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 32px;
`;

const Label = styled.div`
  margin-top: 32px;
  margin-bottom: ${padding.medium}px;
  font-weight: bold;
`;
