import { formatISO } from 'date-fns';

import { Language } from '@/i18n/Language';
import { DiscretionaryOrder } from '@/pages/Order/types/Order';

import { PostDepositOrderDto } from '../dtos/PostDepositOrderDto';

export const mapPostDiscretionaryOrderModelToDepositDto = (
  discretionaryOrder: DiscretionaryOrder,
  language: Language,
): PostDepositOrderDto => {
  return {
    order: {
      portfolioId: discretionaryOrder.portfolioId,
      portfolioBankAccountNumber:
        discretionaryOrder.toPortfolioBankAccountNumber,
      amount: discretionaryOrder.amount,
      currency: discretionaryOrder.currency,
      expectedDepositDate: formatISO(discretionaryOrder.expectedDepositDate, {
        representation: 'date',
      }),
      message: discretionaryOrder.messageToSam?.trim() || null,
      signingProviderId: discretionaryOrder.signingProvider,
      signatories: discretionaryOrder.signatories,
    },
    language,
  };
};
