import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { useFundListQuery } from '@/api/funds/fundsApi';
import { OrderType } from '@/api/order/models/OrderType';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { FormValidationSummary } from '@/components/Form/FormValidationSummary';
import { formHasErrors } from '@/components/Form/utils/formHasErrors';
import { Column, Row } from '@/components/Grid';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { Show } from '@/components/Show';
import { useFms } from '@/hooks/useFms';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { useFundPortfolios } from '../../hooks/useFundPortfolios';
import { RedemptionOrderFormValues } from '../../types/RedemptionOrderFormValues';
import { getDefaultSignatoryOption } from '../../utils/getDefaultSignatoryOption';
import { mapHoldingsToRedemptionFundState } from '../../utils/mapHoldingsToRedemptionFundState';
import { FundPortfolioSelector } from '../FundPortfolioSelector';
import { RedemptionFundsTable } from '../FundsTable';
import { NavigationButtons } from '../NavigationButtons';
import { RedemptionPaymentPlacement } from '../PaymentPlacement';
import { SignatoryOptions } from '../SignatoryOptions';
import { ValueTypeSelector } from '../ValueTypeSelector';
import { createInitialRedemptionValues } from './utils/createInitialRedemptionValues';

export const RedemptionFormContent: React.FC = () => {
  const texts = useFms();

  const { data: fundList } = useFundListQuery();
  const { data: fundHoldings } = useFetchFundHoldingsQuery();
  const portfolios = useFundPortfolios(OrderType.Redemption);

  const hasPermission = useHasPermission();

  const { values, errors, submitCount, resetForm } =
    useFormikContext<RedemptionOrderFormValues>();

  const handlePortfolioOnChange = (portfolioShortName: string): void => {
    const fundStates = mapHoldingsToRedemptionFundState(
      fundList.byIsin,
      fundHoldings.byPortfolioId[portfolioShortName].holdings,
      values.fromValueType,
    );

    const initialValues = createInitialRedemptionValues(
      fundStates,
      portfolioShortName,
      values.fromValueType,
      getDefaultSignatoryOption(hasPermission),
    );

    resetForm({
      values: initialValues,
    });
  };

  const handleValueTypeOnChange = (value: string): void => {
    const valueType = convertStringToEnum(value, OrderValueType);

    const fundStates = mapHoldingsToRedemptionFundState(
      fundList.byIsin,
      fundHoldings.byPortfolioId[values.fromPortfolioShortName].holdings,
      valueType,
    );

    const initialValues = createInitialRedemptionValues(
      fundStates,
      values.fromPortfolioShortName,
      valueType,
      getDefaultSignatoryOption(hasPermission),
    );

    resetForm({
      values: {
        ...initialValues,
        signer: values.signer,
        cosigner: values.cosigner,
      },
    });
  };

  return (
    <Container>
      {portfolios.length > 1 && (
        <Row>
          <Column $span={5}>
            <FundPortfolioSelector
              orderType={OrderType.Redemption}
              initialPortfolioShortName={values.fromPortfolioShortName}
              onChange={handlePortfolioOnChange}
            />
          </Column>
        </Row>
      )}

      {/* Only render when the user has selected a portfolio. */}
      <Show when={!!values.fromPortfolioShortName} animate>
        <Container>
          <TableContainer>
            <ValueTypeWrapper>
              <ValueTypeSelector
                id="value-type"
                dataSource="redemption"
                valueType={values.fromValueType}
                validValueTypes={[
                  OrderValueType.Amount,
                  OrderValueType.Percent,
                  OrderValueType.Units,
                ]}
                onChange={handleValueTypeOnChange}
              />
            </ValueTypeWrapper>

            <RedemptionFundsTable />
          </TableContainer>

          <RedemptionPaymentPlacement />

          <SignatoryOptions />

          <Show when={submitCount > 0 && formHasErrors(errors)} animate>
            <FormValidationSummary
              title={texts.orders.form.errors.validationSummary.title}
              errors={errors}
            />
          </Show>

          <NavigationButtons />
        </Container>
      </Show>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const ValueTypeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
