import DatePicker from '@storeblocks/datepicker';
import { useField, useFormikContext } from 'formik';
import React from 'react';

import { useFms } from '@/hooks/useFms';
import { useUserDateFnsLocale, useUserLocale } from '@/i18n/hooks';
import { nameof } from '@/util/nameof';

import { DepositOrderFormValues } from '../types/DepositOrderFormValues';
import { expectedDepositDate } from '../utils/expectedDepositDate';

export const ExpectedDepositDateSelector: React.FC = () => {
  const texts = useFms();
  const locale = useUserLocale();
  const dateFnsLocale = useUserDateFnsLocale();

  const [field, meta, helpers] = useField<Date>(
    nameof<DepositOrderFormValues>('expectedDepositDate'),
  );

  const { submitCount } = useFormikContext<DepositOrderFormValues>();

  return (
    <DatePicker
      {...field}
      id="expected-deposit-date"
      locale={locale}
      format={expectedDepositDate.dateFormat(dateFnsLocale)}
      min={expectedDepositDate.minimumDate()}
      label={texts.orders.form.depositDate.title}
      hint={texts.orders.form.depositDate.description}
      inputProps={{
        autoComplete: 'off',
        placeholder: expectedDepositDate.dateFormat(dateFnsLocale),
      }}
      // Must also use submit count otherwise the validation error
      // is displayed when the user clicks on the calendar icon.
      error={submitCount > 0 && meta.touched && meta.error}
      // Override the 'onChange' and 'onBlur' as per
      // the Storeblocks documentation.
      onChange={(_, selectedDate) => {
        helpers.setValue(selectedDate);
      }}
      onBlur={() => {
        helpers.setTouched(true);
      }}
      fluid
    />
  );
};
