import Select, { SelectChangeEvent } from '@storeblocks/select';
import { useField, useFormikContext } from 'formik';
import React from 'react';

import { useFms } from '@/hooks/useFms';
import { nameof } from '@/util/nameof';

import { useDiscretionaryPortfolios } from '../hooks/useDiscretionaryPortfolios';
import { DepositOrderFormValues } from '../types/DepositOrderFormValues';
import { createPortfolioBankAccountLabel } from '../utils/createPortfolioBankAccountLabel';

interface Props {
  portfolioId: string;
}

export const DepositPortfolioBankAccountSelect: React.FC<Props> = ({
  portfolioId,
}) => {
  const texts = useFms();

  const [portfolio] = useDiscretionaryPortfolios().filter(
    (portfolio) => portfolio.id === portfolioId,
  );

  const portfolioBankAccounts = (portfolio.portfolioBankAccounts || []).map(
    (account) => ({
      label: createPortfolioBankAccountLabel(account),
      value: account.accountNumber,
    }),
  );

  const [field, meta, helpers] = useField<string>(
    nameof<DepositOrderFormValues>('toPortfolioBankAccount'),
  );

  const { setFieldValue } = useFormikContext<DepositOrderFormValues>();

  const handlePortfolioBankAccountChanged = async (
    event: SelectChangeEvent,
  ): Promise<void> => {
    const accountNumber = event.target.value;

    await helpers.setValue(accountNumber);

    // Set the currency field in the form to the currency
    // of the selected portfolio bank account.
    const [account] = portfolio.portfolioBankAccounts.filter(
      (bankAccount) => bankAccount.accountNumber === accountNumber,
    );
    await setFieldValue(
      nameof<DepositOrderFormValues>('currency'),
      account.currency,
    );
  };

  return (
    <Select
      {...field}
      id="deposit-portfolio-bank-account-selector"
      label={texts.orders.form.portfolioBankAccountSelect.deposit.title}
      hint={texts.orders.form.portfolioBankAccountSelect.deposit.description}
      placeholder={
        texts.orders.form.portfolioBankAccountSelect.deposit.placeholder
      }
      options={portfolioBankAccounts}
      error={meta.touched && meta.error}
      value={field.value || ''}
      onChange={handlePortfolioBankAccountChanged}
      fluid
    />
  );
};
