import { formatISO } from 'date-fns';

import { Language } from '@/i18n/Language';
import { DiscretionaryOrder } from '@/pages/Order/types/Order';

import { PostWithdrawalOrderDto } from '../dtos/PostWithdrawalOrderDto';

export const mapPostDiscretionaryOrderModelToWithdrawalDto = (
  discretionaryOrder: DiscretionaryOrder,
  language: Language,
): PostWithdrawalOrderDto => {
  return {
    order: {
      portfolioId: discretionaryOrder.portfolioId,
      signingProviderId: discretionaryOrder.signingProvider,
      signatories: discretionaryOrder.signatories,
      amount: discretionaryOrder.amount,
      currency: discretionaryOrder.currency,
      message: discretionaryOrder.messageToSam?.trim() || null,
      expectedTransferDate: formatISO(discretionaryOrder.expectedTransferDate, {
        representation: 'date',
      }),
      fromPortfolioBankAccountNumber:
        discretionaryOrder.fromPortfolioBankAccountNumber,
      toExternalBankAccountNumber: discretionaryOrder.externalBankAccount,
    },
    language,
  };
};
