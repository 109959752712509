import { OrderType } from '@/api/order/models/OrderType';
import { useFms } from '@/hooks/useFms';

export const useOrderPageTitle = (orderType: OrderType): string => {
  const texts = useFms();

  switch (orderType) {
    case OrderType.Redemption: {
      return texts.menu.links.trading.redemption;
    }

    case OrderType.Subscription: {
      return texts.menu.links.trading.subscription;
    }

    case OrderType.Switch: {
      return texts.menu.links.trading.switch;
    }

    case OrderType.Deposit: {
      return texts.menu.links.trading.deposit;
    }

    case OrderType.Withdrawal: {
      return texts.menu.links.trading.withdrawal;
    }

    case OrderType.Transfer: {
      return texts.menu.links.trading.transfer;
    }

    default:
      throw new Error(`Invalid order type [${orderType}]`);
  }
};
