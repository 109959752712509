import Select from '@storeblocks/select';
import { useField, useFormikContext } from 'formik';
import React from 'react';

import { usePaymentOptionsQuery } from '@/api/paymentOptions/paymentOptionsApi';
import { useFms } from '@/hooks/useFms';
import { nameof } from '@/util/nameof';

import { WithdrawalOrderFormValues } from '../../types/WithdrawalOrderFormValues';

export const WithdrawalExternalBankAccountSelect: React.FC = () => {
  const texts = useFms();

  const { values } = useFormikContext<WithdrawalOrderFormValues>();

  const [field, meta] = useField<string>(
    nameof<WithdrawalOrderFormValues>('externalBankAccount'),
  );
  const { data } = usePaymentOptionsQuery();

  const options = data.paymentOptions
    .filter(
      (paymentOption) => paymentOption.denominationCurrency === values.currency,
    )
    .map((paymentOption) => ({
      value: paymentOption.accountNumber,
      label: paymentOption.accountNumber,
    }));

  return (
    <Select
      {...field}
      id="external-bank-account-selector"
      label={texts.orders.form.withdrawal.externalBankAccount.title}
      hint={texts.orders.form.withdrawal.externalBankAccount.description}
      placeholder={texts.orders.form.withdrawal.externalBankAccount.placeholder}
      options={options}
      error={meta.touched && meta.error}
      value={field.value || ''}
      fluid
    />
  );
};
