import Alert from '@storeblocks/alert';
import Button from '@storeblocks/button';
import LoadingIndicator from '@storeblocks/loading-indicator';
import Modal, { Content, Footer, Heading } from '@storeblocks/modal';
import { Option } from '@storeblocks/select';
import { AxiosError } from 'axios';
import { Form, Formik } from 'formik';
import React, { FC, useEffect, useState } from 'react';

import { useUpdateDocumentFileMutation } from '@/api/documentArchive/documentArchiveApi';
import { DocumentCategory } from '@/api/documentArchive/models/DocumentCategory';
import { FormInput } from '@/components/Form/FormInput';
import { FormSelect } from '@/components/Form/FormSelect';
import { Show } from '@/components/Show';
import { useFms } from '@/hooks/useFms';
import { useKeyPress } from '@/hooks/useKeyPress';
import { useDocumentsArchiveContext } from '@/pages/Archives/DocumentsArchive/DocumentsArchiveContext';
import { TrackingElement, useGetTrackId } from '@/tracking';
import {
  getFileExtension,
  getFilenameWithoutPathAndExtension,
} from '@/util/FileHelpers';

import { FormRow } from '../Form/FormRow';

interface Props {
  categories: Option[];
}

type DocumentCategoryValues = DocumentCategory | '';

interface FormValues {
  name: string;
  category: DocumentCategoryValues;
}

export const EditFileModal: FC<Props> = ({ categories }) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    category: '',
  });

  const texts = useFms();
  const getTrackId = useGetTrackId();

  const { documentFileToEdit, setDocumentFileToEdit } =
    useDocumentsArchiveContext();

  const [updateDocumentFile, updateDocumentFileRequest] =
    useUpdateDocumentFileMutation();

  useEffect(() => {
    setShowLoader(false);
    if (documentFileToEdit) {
      setFormValues({
        name: getFilenameWithoutPathAndExtension(documentFileToEdit?.name),
        category: documentFileToEdit?.category || '',
      });
    }
  }, [setFormValues, getFilenameWithoutPathAndExtension, documentFileToEdit]);

  useKeyPress(() => {
    setDocumentFileToEdit(null);
  }, ['Escape']);

  const onSubmit = async (values: FormValues): Promise<void> => {
    setShowLoader(true);
    const newName = values.name.trim();

    if (
      documentFileToEdit &&
      (`${newName}.${getFileExtension(documentFileToEdit.name)}` !==
        documentFileToEdit.name ||
        values.category !== documentFileToEdit.category)
    ) {
      await updateDocumentFile({
        fileId: documentFileToEdit.id,
        fileName: `${newName}.${getFileExtension(documentFileToEdit.name)}`,
        category: values.category || undefined,
      });
      setDocumentFileToEdit(null);
    }
  };

  const onCancel = (): void => {
    if (!showLoader) {
      setDocumentFileToEdit(null);
    }
  };

  return (
    <Formik initialValues={formValues} onSubmit={onSubmit} enableReinitialize>
      <Form id="edit-file-form">
        <Modal
          trigger={undefined}
          open={!!documentFileToEdit}
          onClose={onCancel}
        >
          <Heading>{texts.editFileModal.editDocument}</Heading>
          <Content data-test="edit-file-modal">
            <FormRow>
              <FormInput
                fluid
                id="name"
                name="name"
                label={texts.reportsArchivePage.tableHeaders.name}
              />
              <FormSelect
                name="category"
                label={texts.category.text}
                options={[
                  {
                    label: `${texts.documentsArchivePage.chooseCategory}...`,
                    value: '',
                  },
                  ...categories,
                ]}
                fluid
              />
            </FormRow>
            {updateDocumentFileRequest.isError && (
              <Alert
                variant="error"
                title={(updateDocumentFileRequest.error as AxiosError)?.message}
              />
            )}
          </Content>
          <Footer>
            <SubmitButton
              showLoader={showLoader}
              buttonText={texts.documentsArchivePage.uploadFileModal.save}
              key="modal-submit"
              trackId={getTrackId(
                'save:edit-file-modal',
                TrackingElement.Button,
              )}
            />
            <Button
              variant="outlined"
              onClick={onCancel}
              disabled={showLoader}
              key="modal-cancel"
              data-trackid={getTrackId(
                'cancel:edit-file-modal',
                TrackingElement.Button,
              )}
            >
              {texts.admin.cancel}
            </Button>
          </Footer>
        </Modal>
      </Form>
    </Formik>
  );
};

interface SubmitButtonProps {
  showLoader: boolean;
  buttonText: string;
  trackId: string;
}

const SubmitButton: FC<SubmitButtonProps> = ({
  showLoader,
  buttonText,
  trackId,
}) => {
  return (
    <Button
      type="submit"
      disabled={showLoader}
      key="modal-submit"
      form="edit-file-form"
      mr="8px"
      data-trackid={trackId}
      data-test="edit-file-modal-submit"
    >
      <Show when={!showLoader}>{buttonText}</Show>
      <Show when={showLoader}>
        <LoadingIndicator />
      </Show>
    </Button>
  );
};
