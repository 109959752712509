import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../axios/axiosBaseQuery';
import { OrderListReply } from './dtos/OrderListReply';
import { Order } from './models/Order';
import { transformOrderListReply } from './transformOrderListReply';

export const orderListApi = createApi({
  reducerPath: 'orderListApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    orderList: builder.query<Order[], void>({
      query: () => {
        return {
          url: `${window.config.connectBaseApiUrl}/api/order-list/fund-orders`,
        };
      },
      transformResponse: (reply: OrderListReply) => {
        return reply.orders.map((order) => transformOrderListReply(order));
      },
    }),
  }),
});

export const { useOrderListQuery } = orderListApi;
