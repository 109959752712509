import { OrderTypeDto } from '../dtos/OrderTypeDto';
import { OrderType } from '../models/OrderType';

export const mapOrderTypeDtoToEnum = (orderType: OrderTypeDto): OrderType => {
  switch (orderType) {
    case OrderTypeDto.BuyFund: {
      return OrderType.Subscription;
    }

    case OrderTypeDto.SellFund: {
      return OrderType.Redemption;
    }

    case OrderTypeDto.Switch: {
      return OrderType.Switch;
    }

    case OrderTypeDto.DepositDiscretionary: {
      return OrderType.Deposit;
    }

    default:
      throw new Error(`Invalid order type [${orderType}]`);
  }
};
