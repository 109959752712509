import React, { FC } from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';

import { DepositOrderForm } from './DepositOrderForm/DepositOrderForm';
import { RedemptionOrderForm } from './RedemptionOrderForm';
import { SubscriptionOrderForm } from './SubscriptionOrderForm';
import { SwitchOrderForm } from './SwitchOrderForm';
import { TransferOrderForm } from './TransferOrderForm/TransferOrderForm';
import { WithdrawalOrderForm } from './WithdrawalOrderForm/WithdrawalOrderForm';

export const OrderForm: FC = () => {
  const { orderType } = useOrderPageContext();

  switch (orderType) {
    case OrderType.Subscription:
      return <SubscriptionOrderForm />;

    case OrderType.Redemption:
      return <RedemptionOrderForm />;

    case OrderType.Switch:
      return <SwitchOrderForm />;

    case OrderType.Deposit:
      return <DepositOrderForm />;

    case OrderType.Withdrawal:
      return <WithdrawalOrderForm />;

    case OrderType.Transfer:
      return <TransferOrderForm />;

    default:
      throw new Error(`Could not find form for order type [${orderType}].`);
  }
};
