import React from 'react';

import { isDiscretionaryType, isFundType } from '@/api/order/models/OrderType';

import { useOrderPageContext } from '../../OrderPageContext';
import { DiscretionaryOrderConfirm } from './DiscretionaryOrderConfirm';
import { FundOrderConfirm } from './FundOrderConfirm';

export const Confirm: React.FC = () => {
  const { orderType } = useOrderPageContext();

  if (isFundType(orderType)) {
    return <FundOrderConfirm />;
  }

  if (isDiscretionaryType(orderType)) {
    return <DiscretionaryOrderConfirm />;
  }

  throw new Error(
    `Order confirm page not implemented for order type [${orderType}].`,
  );
};
