import { Form, Formik } from 'formik';
import React from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useCurrentUserInfo } from '@/auth/hooks';
import { Show } from '@/components/Show';
import { H3 } from '@/components/Typography';
import { useFms } from '@/hooks/useFms';
import { useOrderSteps } from '@/pages/Order/components/OrderStepsProvider';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';

import { useFundPortfolios } from '../../hooks/useFundPortfolios';
import { SubscriptionOrderFormValues } from '../../types/SubscriptionOrderFormValues';
import { mapSubscriptionFundStatesToOrderPlacements } from '../../utils/mapSubscriptionFundStatesToOrderPlacements';
import { useCreateSubscriptionValidationSchema } from '../../validationSchemas/hooks/useCreateSubscriptionValidationSchema';
import { useInitialSubscriptionFormValues } from './hooks/useInitialSubscriptionFormValues';
import { SubscriptionFormContent } from './SubscriptionFormContent';

export const SubscriptionOrderForm: React.FC = () => {
  const { orders } = useFms();
  const userInfo = useCurrentUserInfo();

  const { completeCurrentStep } = useOrderSteps();
  const { setCurrentFundOrder } = useOrderPageContext();

  const portfolios = useFundPortfolios(OrderType.Subscription);
  const hasNoFundPortfolio = portfolios.length === 0;

  const initialValues = useInitialSubscriptionFormValues();
  const validationSchema = useCreateSubscriptionValidationSchema();

  const handleSubmit = (values: SubscriptionOrderFormValues): void => {
    const orderPlacements = mapSubscriptionFundStatesToOrderPlacements(
      values.toFunds,
      values.payments,
    );

    const fundOrder = {
      userId: userInfo.userCmId,
      portfolioId: values.toPortfolioShortName,
      signatories: [values.signer, values.cosigner].filter(Boolean),
      payments: values.payments,
      orderPlacements,
      signingProvider: values.signingProvider,
    };

    setCurrentFundOrder(fundOrder);
    completeCurrentStep();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form data-test="subscription-order-form">
        <Show when={hasNoFundPortfolio}>
          <H3 data-test="no-fund-portfolio">
            {orders.form.subscription.noFundPortfolios}
          </H3>
        </Show>
        <SubscriptionFormContent />
      </Form>
    </Formik>
  );
};
