import { PostDepositOrderDto } from '@/api/order/dtos/PostDepositOrderDto';
import { PostWithdrawalOrderDto } from '@/api/order/dtos/PostWithdrawalOrderDto';
import { OrderType } from '@/api/order/models/OrderType';
import { mapPostDiscretionaryOrderModelToDepositDto } from '@/api/order/utils/mapPostDiscretionaryOrderModelToDepositDto';
import { mapPostDiscretionaryOrderModelToWithdrawalDto } from '@/api/order/utils/mapPostDiscretionaryOrderModelToWithdrawalDto';
import { Language } from '@/i18n/Language';
import { DiscretionaryOrder } from '@/pages/Order/types/Order';

export const mapPostDiscretionaryOrderModelToDto = (
  order: DiscretionaryOrder,
  orderType: OrderType,
  language: Language,
): PostDepositOrderDto | PostWithdrawalOrderDto => {
  switch (orderType) {
    case OrderType.Deposit:
      return mapPostDiscretionaryOrderModelToDepositDto(order, language);
    case OrderType.Withdrawal:
      return mapPostDiscretionaryOrderModelToWithdrawalDto(order, language);
    default:
      throw new Error(
        `No mapping to DTO exists for order type [${orderType}]. `,
      );
  }
};
