import { parseISO } from 'date-fns';

import { CurrencyCode } from '@/types/CurrencyCode';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { OrderDto } from '../dtos/OrderDto';
import { OrderPlacementDto } from '../dtos/OrderPlacementDto';
import { Order } from '../models/Order';
import { OrderPaymentType } from '../models/OrderPaymentType';
import { OrderPlacement } from '../models/OrderPlacement';
import { OrderPlacementType } from '../models/OrderPlacementType';
import { OrderValueType } from '../models/OrderValueType';
import { mapOrderTypeDtoToEnum } from './mapOrderTypeDtoToEnum';

export function mapOrderDtoToModel(order: OrderDto): Order {
  return {
    id: order.id,
    orderPlacements: order.orderPlacements?.map(mapOrderPlacementDtoToModel),
    portfolioNumber: order.portfolioNumber,
    orderType: mapOrderTypeDtoToEnum(order.orderType),
    userId: order.userId,
    signatories: order.signatories,

    // Discretionary order fields.
    messageToSam: order.specifications,
    amount: order.amount,
    currency: convertStringToEnum(order.currencyCode, CurrencyCode),

    // Discretionary deposit order fields.
    toPortfolioBankAccountNumber: order.portfolioBankAccountNumber,
    expectedDepositDate: parseISO(order.expectedDepositDate),

    // Discretionary withdrawal order fields.
    fromPortfolioBankAccountNumber: order.fromPortfolioBankAccountNumber,
    externalBankAccount: order.externalBankAccount,
    expectedTransferDate: parseISO(order.expectedTransferDate),
  };
}

const mapOrderPlacementDtoToModel = (
  dto: OrderPlacementDto,
): OrderPlacement => {
  return {
    fundIsin: dto.isin,
    hash: dto.hash,
    valueType: convertStringToEnum(dto.valueType, OrderValueType),
    accountId: dto.accountId,
    // Set default currency value to `NOK` for old orders missing currency.
    currency: convertStringToEnum(dto.currency || 'NOK', CurrencyCode),
    externalAccountNumber: dto.externalAccountNumber,
    fundName: dto.fundName,
    id: dto.id,
    isin: dto.isin,
    payment: convertStringToEnum(dto.payment, OrderPaymentType),
    placementType: convertStringToEnum(dto.placementType, OrderPlacementType),
    value: dto.value,
  };
};
