import { OrderType } from '@/api/order/models/OrderType';

export const getOrderPagePath = (orderType: OrderType): string => {
  switch (orderType) {
    case OrderType.Redemption: {
      return 'redemption';
    }

    case OrderType.Subscription: {
      return 'subscription';
    }

    case OrderType.Switch: {
      return 'switch';
    }

    case OrderType.Deposit: {
      return 'deposit';
    }

    case OrderType.Withdrawal: {
      return 'withdrawal';
    }

    case OrderType.Transfer: {
      return 'transfer';
    }

    default:
      throw new Error(`Invalid order type [${orderType}]`);
  }
};
