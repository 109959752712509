import React, { FC } from 'react';
import { NumericFormat } from 'react-number-format';

import { useUserLocale } from '@/i18n/hooks';

import { NumericFormatProps } from '../types/NumericFormatProps';
import { getNumberSeparators } from '../utils/getNumberSeparators';
import { AmountBaseInput } from './AmountBaseInput';

interface Props {
  id: string;
  name: string;
  value: number;
  decimalScale?: number;
  disabled?: boolean;
  error?: string | boolean;
  suffix?: string;
  onChange: (units: number) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}

export const FundInput: FC<Props> = ({
  id,
  name,
  value,
  decimalScale = 0,
  disabled,
  error,
  suffix,
  onChange,
  onBlur,
}) => {
  const locale = useUserLocale();

  const numberSeparators = getNumberSeparators(locale);

  return (
    <NumericFormat<NumericFormatProps>
      id={id}
      name={name}
      error={error}
      value={value > 0 ? value : ''}
      valueIsNumericString
      thousandSeparator={numberSeparators.thousandSeparator}
      decimalSeparator={numberSeparators.decimalSeparator}
      decimalScale={decimalScale}
      allowNegative={false}
      onValueChange={(values) => {
        onChange(values.floatValue || 0);
      }}
      autoComplete="off"
      disabled={disabled}
      onBlur={onBlur}
      customInput={AmountBaseInput}
      customSuffix={suffix}
    />
  );
};
