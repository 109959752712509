import { OrderType } from '@/api/order/models/OrderType';

const orderTypeMap = {
  [OrderType.Subscription]: 'trading/subscription',
  [OrderType.Redemption]: 'trading/redemption',
  [OrderType.Switch]: 'trading/switch',
  [OrderType.Deposit]: 'discretionary/deposit',
};

export const createLinkToOrder = (
  orderId: number,
  organizationCmId: string,
  orderType: OrderType,
): string =>
  `/${organizationCmId}/${orderTypeMap[orderType]}?orderId=${orderId}`;
