import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { useFms } from '@/hooks/useFms';
import { useUserDateFnsLocale, useUserLocale } from '@/i18n/hooks';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { formatNumber } from '@/util/formatNumber';

import { useDiscretionaryPortfolios } from '../../OrderDetails/hooks/useDiscretionaryPortfolios';
import { createPortfolioBankAccountLabel } from '../../OrderDetails/utils/createPortfolioBankAccountLabel';
import { expectedTransferDate } from '../../OrderDetails/utils/expectedTransferDate';
import { MainHeaderRow } from './MainHeaderRow';
import { PortfolioRow } from './PortfolioRow';
import { Row } from './Row';

export const WithdrawalOrderDetailsTable: React.FC = () => {
  const texts = useFms();
  const locale = useUserLocale();
  const dateFnsLocale = useUserDateFnsLocale();

  const { currentDiscretionaryOrder } = useOrderPageContext();

  const [portfolio] = useDiscretionaryPortfolios().filter(
    (portfolio) => portfolio.id === currentDiscretionaryOrder.portfolioId,
  );

  const [portfolioBankAccount] = portfolio.portfolioBankAccounts.filter(
    (account) =>
      account.accountNumber ===
      currentDiscretionaryOrder.fromPortfolioBankAccountNumber,
  );

  return (
    <StyledTable
      cellSpacing="0"
      cellPadding="0"
      data-test="withdrawal-order-details"
    >
      <thead>
        <MainHeaderRow title={texts.orders.form.table.orderDetails} />
      </thead>

      <tbody>
        <PortfolioRow
          portfolioShortName={currentDiscretionaryOrder.portfolioId}
        />

        <Row
          left={texts.orders.form.table.portfolioBankAccount}
          right={createPortfolioBankAccountLabel(portfolioBankAccount)}
        />

        <Row
          left={texts.orders.form.table.externalBankAccount}
          right={currentDiscretionaryOrder.externalBankAccount}
        />

        <Row
          left={texts.orders.form.table.amount}
          right={formatNumber(
            currentDiscretionaryOrder.amount,
            locale,
            currentDiscretionaryOrder.currency,
          )}
        />

        <Row
          left={texts.orders.form.table.date}
          right={format(
            currentDiscretionaryOrder.expectedTransferDate,
            expectedTransferDate.dateFormat(dateFnsLocale),
          )}
        />

        <Row
          left={texts.orders.form.table.messageToSam}
          right={
            currentDiscretionaryOrder.messageToSam?.trim() ||
            texts.orders.form.table.messageNotSpecified
          }
        />
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  line-height: 16px;
  width: 100%;
`;
