import Input from '@storeblocks/input';
import Select, { Option, SelectChangeEvent } from '@storeblocks/select';
import { useFormikContext } from 'formik';
import React from 'react';

import { FormValidationSummary } from '@/components/Form/FormValidationSummary';
import { formHasErrors } from '@/components/Form/utils/formHasErrors';
import { Column, Row } from '@/components/Grid';
import { WithGap } from '@/components/WithGap';
import { useFms } from '@/hooks/useFms';
import { nameof } from '@/util/nameof';

import { useDiscretionaryPortfolios } from '../../hooks/useDiscretionaryPortfolios';
import { DepositOrderFormValues } from '../../types/DepositOrderFormValues';
import { DepositAmountInput } from '../DepositAmountInput';
import { DepositPortfolioBankAccountSelect } from '../DepositPortfolioBankAccountSelect';
import { ExpectedDepositDateSelector } from '../ExpectedDepositDateSelector';
import { NavigationButtons } from '../NavigationButtons';
import { SignatoryOptions } from '../SignatoryOptions';
import { SinglePortfolioBankAccount } from '../SinglePortfolioBankAccount';

export const DepositOrderFormContent: React.FC = () => {
  const texts = useFms();

  const { values, touched, errors, submitCount, getFieldProps, setFieldValue } =
    useFormikContext<DepositOrderFormValues>();

  const portfolios = useDiscretionaryPortfolios();
  const options = portfolios.map<Option>((portfolio) => ({
    value: portfolio.id,
    label: `${portfolio.id} - ${portfolio.name}`,
  }));

  const [selectedPortfolio] = portfolios.filter(
    (portfolio) => portfolio.id === values.portfolioId,
  );

  const handlePortfolioChanged = async (
    event: SelectChangeEvent,
  ): Promise<void> => {
    const { onChange } = getFieldProps(
      nameof<DepositOrderFormValues>('portfolioId'),
    );
    onChange(event);

    const portfolioId = event.target.value;
    const portfolioBankAccounts = portfolios.filter(
      (portfolio) => portfolio.id === portfolioId,
    )[0].portfolioBankAccounts;

    if (portfolioBankAccounts.length === 1) {
      await setFieldValue(
        nameof<DepositOrderFormValues>('toPortfolioBankAccount'),
        portfolioBankAccounts[0].accountNumber,
      );

      await setFieldValue(
        nameof<DepositOrderFormValues>('currency'),
        portfolioBankAccounts[0].currency,
      );
    }
  };

  return (
    <WithGap gap="48">
      {portfolios.length > 1 && (
        <Row>
          <Column $span={6}>
            <Select
              {...getFieldProps(nameof<DepositOrderFormValues>('portfolioId'))}
              id="portfolio-selector"
              label={texts.orders.form.portfolio.title}
              hint={texts.orders.form.portfolio.message}
              placeholder={texts.orders.form.portfolio.placeholder}
              error={touched.portfolioId && errors.portfolioId}
              options={options}
              fluid
              onChange={handlePortfolioChanged}
            />
          </Column>
        </Row>
      )}

      {values.portfolioId && (
        <>
          <Row>
            <Column $span={6}>
              {selectedPortfolio.portfolioBankAccounts.length > 1 ? (
                <DepositPortfolioBankAccountSelect
                  portfolioId={selectedPortfolio.id}
                />
              ) : (
                <SinglePortfolioBankAccount
                  portfolioBankAccountNumber={
                    selectedPortfolio.portfolioBankAccounts[0].accountNumber
                  }
                />
              )}
            </Column>
          </Row>

          <Row>
            <Column $span={6}>
              <DepositAmountInput />
            </Column>
          </Row>

          <Row>
            <Column $span={6}>
              <ExpectedDepositDateSelector />
            </Column>
          </Row>

          <Row>
            <Column $span={6}>
              <Input
                {...getFieldProps(
                  nameof<DepositOrderFormValues>('messageToSam'),
                )}
                as="textarea"
                id="message-to-sam"
                label={texts.orders.form.messageToSam.title}
                hint={texts.orders.form.messageToSam.description.deposit}
                error={touched.messageToSam && errors.messageToSam}
                type="text"
                fluid
              />
            </Column>
          </Row>

          <SignatoryOptions />
        </>
      )}

      {submitCount > 0 && formHasErrors(errors) && (
        <FormValidationSummary
          title={texts.orders.form.errors.validationSummary.title}
          errors={errors}
        />
      )}

      {values.portfolioId && <NavigationButtons />}
    </WithGap>
  );
};
