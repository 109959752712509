export enum OrderType {
  Subscription = 'subscription',
  Redemption = 'redemption',
  Switch = 'switch',
  Deposit = 'deposit',
  Withdrawal = 'withdrawal',
  Transfer = 'transfer',
}

export const isFundType = (orderType: OrderType): boolean => {
  return (
    orderType === OrderType.Subscription ||
    orderType === OrderType.Redemption ||
    orderType === OrderType.Switch
  );
};

export const isDiscretionaryType = (orderType: OrderType): boolean => {
  return (
    orderType === OrderType.Deposit ||
    orderType === OrderType.Withdrawal ||
    orderType === OrderType.Transfer
  );
};
