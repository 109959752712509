import { useField, useFormikContext } from 'formik';
import React from 'react';
import { NumericFormat } from 'react-number-format';

import { useFms } from '@/hooks/useFms';
import { useUserLocale } from '@/i18n/hooks';
import { nameof } from '@/util/nameof';

import { DepositOrderFormValues } from '../types/DepositOrderFormValues';
import { AmountBaseInput } from './FundsTable/components/AmountBaseInput';
import { NumericFormatProps } from './FundsTable/types/NumericFormatProps';
import { getNumberSeparators } from './FundsTable/utils/getNumberSeparators';

export const DepositAmountInput: React.FC = () => {
  const texts = useFms();

  const locale = useUserLocale();
  const numberSeparators = getNumberSeparators(locale);

  const [field, meta, helpers] = useField(
    nameof<DepositOrderFormValues>('amount'),
  );

  const { values } = useFormikContext<DepositOrderFormValues>();

  return (
    <NumericFormat<NumericFormatProps>
      {...field}
      id="amount"
      error={meta.touched && meta.error}
      value={meta.value > 0 ? meta.value : ''}
      thousandSeparator={numberSeparators.thousandSeparator}
      decimalSeparator={numberSeparators.decimalSeparator}
      decimalScale={0}
      allowNegative={false}
      onValueChange={(values) => {
        helpers.setValue(values.floatValue || 0);
      }}
      onChange={() => {
        // noop:
        // The 'onChange' is here to override the function from the
        // spreading of the field property. Updating the value is handled
        // by the 'onValueChange' event.
      }}
      autoComplete="off"
      customInput={AmountBaseInput}
      customSuffix={values.currency}
      label={texts.orders.form.discretionary.deposit.amount.title}
    />
  );
};
